import BellSVGComponent from "@aviary/illustrations/Bell";
import ClientOrdersSVGComponent from "@aviary/illustrations/ClientOrders";
import EmptyBottleSVGComponent from "@aviary/illustrations/EmptyBottle";
import EnvelopeSVGComponent from "@aviary/illustrations/Envelope";
import FavoriteProductsSVGComponent from "@aviary/illustrations/FavoriteProducts";
import LabSVGComponent from "@aviary/illustrations/Lab";
import LighthouseSVGComponent from "@aviary/illustrations/Lighthouse";
import MoneySVGComponent from "@aviary/illustrations/Money";
import MountainsSVGComponent from "@aviary/illustrations/Mountains";
import NoSwapsSVGComponent from "@aviary/illustrations/NoSwaps";
import PaperPlaneSVGComponent from "@aviary/illustrations/PaperPlane";
import PastSwapsSVGComponent from "@aviary/illustrations/PastSwaps";
import PeopleSVGComponent from "@aviary/illustrations/People";
import PersonSVGComponent from "@aviary/illustrations/Person";
import PrescriptionCardSVGComponent from "@aviary/illustrations/PrescriptionCard";
import PrescriptionDocSVGComponent from "@aviary/illustrations/PrescriptionDoc";
import PrescriptionLaptopSVGComponent from "@aviary/illustrations/PrescriptionLaptop";
import RocketSVGComponent from "@aviary/illustrations/Rocket";
import SelectSVGComponent from "@aviary/illustrations/Select";
import StaffSVGComponent from "@aviary/illustrations/Staff";
import SupplementsSVGComponent from "@aviary/illustrations/Supplements";
import { AVIARY_ILLUSTRATIONS, type AviaryIllustration } from "@aviary/types";

import * as styles from "./Illustration.styles";

interface Props {
  /**
   * An Aviary Illustration
   */
  illustration: AviaryIllustration;
  /**
   * Option to change width and height (in rem)
   */
  heightAndWidth?: number;
  /**
   * Option to change to mobile size
   */
  isMobile?: boolean;
}

/**
 * Documentation:
 * https://aviary.docs.fullscript.cloud/components/display/Illustration
 */
const Illustration = ({ illustration, heightAndWidth, isMobile, ...rest }: Props) => {
  const svgStyles = [
    styles.size.desktop,
    isMobile && styles.size.mobile,
    heightAndWidth && styles.heightAndWidth(heightAndWidth),
  ];

  const renderSvg = () => {
    switch (illustration) {
      case AVIARY_ILLUSTRATIONS.bell:
        return (
          <BellSVGComponent
            aria-hidden
            data-testid={AVIARY_ILLUSTRATIONS.bell}
            css={svgStyles}
            {...rest}
          />
        );
      case AVIARY_ILLUSTRATIONS.document:
        return (
          <PrescriptionDocSVGComponent
            aria-hidden
            data-testid={AVIARY_ILLUSTRATIONS.document}
            css={svgStyles}
            {...rest}
          />
        );
      case AVIARY_ILLUSTRATIONS.emptyBottle:
        return (
          <EmptyBottleSVGComponent
            aria-hidden
            data-testid={AVIARY_ILLUSTRATIONS.emptyBottle}
            css={svgStyles}
            {...rest}
          />
        );
      case AVIARY_ILLUSTRATIONS.emptyOrderBox:
        return (
          <FavoriteProductsSVGComponent
            aria-hidden
            data-testid={AVIARY_ILLUSTRATIONS.emptyOrderBox}
            css={svgStyles}
            {...rest}
          />
        );
      case AVIARY_ILLUSTRATIONS.lab:
        return (
          <LabSVGComponent
            aria-hidden
            data-testid={AVIARY_ILLUSTRATIONS.lab}
            css={svgStyles}
            {...rest}
          />
        );
      case AVIARY_ILLUSTRATIONS.envelope:
        return (
          <EnvelopeSVGComponent
            aria-hidden
            data-testid={AVIARY_ILLUSTRATIONS.envelope}
            css={svgStyles}
            {...rest}
          />
        );
      case AVIARY_ILLUSTRATIONS.handSelect:
        return (
          <SelectSVGComponent
            aria-hidden
            data-testid={AVIARY_ILLUSTRATIONS.handSelect}
            css={svgStyles}
            {...rest}
          />
        );
      case AVIARY_ILLUSTRATIONS.idCardAndSupplements:
        return (
          <StaffSVGComponent
            aria-hidden
            data-testid={AVIARY_ILLUSTRATIONS.idCardAndSupplements}
            css={svgStyles}
            {...rest}
          />
        );
      case AVIARY_ILLUSTRATIONS.laptop:
        return (
          <PrescriptionLaptopSVGComponent
            aria-hidden
            data-testid={AVIARY_ILLUSTRATIONS.laptop}
            css={svgStyles}
            {...rest}
          />
        );
      case AVIARY_ILLUSTRATIONS.lighthouse:
        return (
          <LighthouseSVGComponent
            aria-hidden
            data-testid={AVIARY_ILLUSTRATIONS.lighthouse}
            css={svgStyles}
            {...rest}
          />
        );
      case AVIARY_ILLUSTRATIONS.money:
        return (
          <MoneySVGComponent
            aria-hidden
            data-testid={AVIARY_ILLUSTRATIONS.money}
            css={svgStyles}
            {...rest}
          />
        );
      case AVIARY_ILLUSTRATIONS.mountains:
        return (
          <MountainsSVGComponent
            aria-hidden
            data-testid={AVIARY_ILLUSTRATIONS.mountains}
            css={svgStyles}
            {...rest}
          />
        );
      case AVIARY_ILLUSTRATIONS.orderBox:
        return (
          <ClientOrdersSVGComponent
            aria-hidden
            data-testid={AVIARY_ILLUSTRATIONS.orderBox}
            css={svgStyles}
            {...rest}
          />
        );
      case AVIARY_ILLUSTRATIONS.paperPlane:
        return (
          <PaperPlaneSVGComponent
            aria-hidden
            data-testid={AVIARY_ILLUSTRATIONS.paperPlane}
            css={svgStyles}
            {...rest}
          />
        );
      case AVIARY_ILLUSTRATIONS.people:
        return (
          <PeopleSVGComponent
            aria-hidden
            data-testid={AVIARY_ILLUSTRATIONS.people}
            css={svgStyles}
            {...rest}
          />
        );
      case AVIARY_ILLUSTRATIONS.person:
        return (
          <PersonSVGComponent
            aria-hidden
            data-testid={AVIARY_ILLUSTRATIONS.person}
            css={svgStyles}
            {...rest}
          />
        );
      case AVIARY_ILLUSTRATIONS.plant:
        return (
          <NoSwapsSVGComponent
            aria-hidden
            data-testid={AVIARY_ILLUSTRATIONS.plant}
            css={svgStyles}
            {...rest}
          />
        );
      case AVIARY_ILLUSTRATIONS.productCard:
        return (
          <PrescriptionCardSVGComponent
            aria-hidden
            data-testid={AVIARY_ILLUSTRATIONS.productCard}
            css={svgStyles}
            {...rest}
          />
        );
      case AVIARY_ILLUSTRATIONS.rocket:
        return (
          <RocketSVGComponent
            aria-hidden
            data-testid={AVIARY_ILLUSTRATIONS.rocket}
            css={svgStyles}
            {...rest}
          />
        );
      case AVIARY_ILLUSTRATIONS.supplements:
        return (
          <SupplementsSVGComponent
            aria-hidden
            data-testid={AVIARY_ILLUSTRATIONS.supplements}
            css={svgStyles}
            {...rest}
          />
        );
      case AVIARY_ILLUSTRATIONS.swapProducts:
        return (
          <PastSwapsSVGComponent
            aria-hidden
            data-testid={AVIARY_ILLUSTRATIONS.swapProducts}
            css={svgStyles}
            {...rest}
          />
        );
    }
  };

  return <>{renderSvg()}</>;
};

export { Illustration };
